<template>

  <ur-page-container ref="page" class="msp" :show-title="false" title="고객등록동의" :topButton="false">
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '고객등록동의' : '고객등록동의'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="h1px" />
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template> 

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- MSPBC411D 컨설턴트영역 -->
            <MSPBC411D v-if="vGssAuthYN === 'Y'" @searchCallback="fn_SrchCallback" />

            <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-list2 types3 mb30">
              <span class="fs18rem pl24 pt30 fwm pb10">고객 동의 방법</span>
              <div class="list-item-wrapper" style="overflow: unset;">
                <div class="list-item listItemLine" :class="{'active' : aprvType === '01'}" @click="aprvType='01'">
                  <div class="list-item__main">
                    <mo-radio v-model="aprvType" value="01" checked></mo-radio>
                    <div class="list-item__contents fs17rem">모바일 동의(휴대폰/카카오/신용카드)</div>
                  </div>
                  <!-- <div class="listComment">본인인증방식으로 동의진행</div> -->
                </div>
                <div class="list-item listItemLine" :class="{'active' : aprvType === '02'}" @click="aprvType='02'">
                  <div class="list-item__main">
                    <mo-radio v-model="aprvType" value="02"></mo-radio>
                    <div class="list-item__contents fs17rem">
                      <div class="tit">지류동의서</div>
                    </div>
                    <mo-button color="normal" shape="border" size="small" class="black btnNoWrap" @click="fn_BtnClickJr">승인현황</mo-button>
                  </div>
                  <div class="listComment">지류작성으로 동의진행</div> <!-- 2025.3.17 :chan -->
                </div>
                <div class="list-item listItemLine" :class="{'active' : aprvType === '03'}" @click="aprvType='03'">
                  <div class="list-item__main">
                    <mo-radio v-model="aprvType" value="03"></mo-radio>
                    <div class="list-item__contents fs17rem">
                      <div class="tit">비대면 고객동의</div>
                    </div>
                    <mo-button color="normal" shape="border" size="small" class="black btnNoWrap" @click="fn_BtnClick">발송이력</mo-button>
                  </div>
                  <div class="listComment">고객이 셀프로 동의하도록 카카오톡으로 전송</div> <!-- 2025.3.17 :chan -->
                  <div v-if="showTooltip" class="chat-bubble type-M1 blu2" style="bottom: -25px;">
                    <div class="exp">이제는 신규고객에게도<br>카카오톡으로 동의를 요청할 수 있어요!</div>
                    <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="fn_CloseTooltip" />
                  </div>
                </div>
              </div>
              <!-- 추가 2025.2.10 :chan -->
              <div class="skyblueBoxWrap">
                <div class="skyblueBox">
                  <div class="skyblueBoxSubject">동의 관련</div>
                  <mo-button class="blueLabelBtn" @click="fn_openMSPCM217M">필수컨설팅 만료예정고객</mo-button>
                  <mo-button class="blueLabelBtn" @click="fn_openMSPCM170M">정보활용동의현황</mo-button>
                  <mo-button class="blueLabelBtn" @click="fn_openMSPCM220M">미승인고객</mo-button>
                </div>
                <div class="chat-bubble chat-bubble-bottom blue agreeTip" v-if="showGuideToolTip">
                  <span>동의 관련 메뉴를 여기에서 <br>한번에 볼 수 있어요!</span>
                  <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="fn_showGuideToolTip">msp-close-bold</mo-icon>
                </div>
              </div>
              <!-- 추가 끝 2025.2.10 :chan -->
            </ur-box-container>

            <template>
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
                <div class="relative-div">
                  <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MovePage">다음</mo-button>
                </div>
              </ur-box-container>
            </template>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>

</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBC411D from '@/ui/bc/MSPBC411D'

export default {
/************************************************************************************************
 * Vue 파일 속성 정의 영역
************************************************************************************************/
  name: 'MSPCM500M',
  screenId: 'MSPCM500M',
  components: { MSPBC411D },
  /************************************************************************************************
   * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
   return {
      title: '고객등록동의 진입',
      lv_UserInfo: {}, // 사용자 정보
      lv_RouteParam: {}, // 전달받은 라우터 파라미터
      lv_HeaderviewScrollCompID:'',
      vGssAuthYN: '', //ga매니저 권한
      vEplyNo: '', // ga컨설턴트ID
      vGssEplyNm: '', // ga컨설턴트명

      preSrnId: '',   // 송신 화면ID
      aprvType: '01',  // 고객등록타입 (default: '01'/모바일 고객등록)
      showGuideToolTip: true
    }
  },
  /************************************************************************************************
   * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  created() {
    console.log('■■■■■■ MSPCM500M ■■■■■■')
    this.showGuideToolTip = !localStorage.getItem('READ_GA_AGREE_BUBBLETIP')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo

    //ga매니저 권한
    this.vGssAuthYN = this.getStore('cmStore').getters.getState.gssAuthYN

    this.fn_Init()

    window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', 0)
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  mounted() {
    const lv_Vm = this

    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM500M')
  },
  destroyed() {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /***********************************************
     * @function fn_Init
     * @notice   화면 진입시, 최초 실행 함수
    ***********************************************/
    fn_Init () {
      let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
      this.lv_RouteParam.srnId = !_.isEmpty(preSrnObj) ? preSrnObj.srnId : 'MSPBC002M'

      if (!this.$bizUtil.isEmpty(this.$route.params.srnId)) {
        let rtnParam = this.$route.params.rtnParam

        this.lv_RouteParam = {
          srnId: this.$route.params.srnId ? this.$route.params.srnId : '',
          custNm: !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : '',
          infoPrcusCnsntTypCd: this.$route.params.infoPrcusCnsntTypCd,
          aprvType: this.$route.params.aprvType,
          rtnParam: !_.isEmpty(rtnParam) ? rtnParam : {}
        }

        // 고객등록 Store 진입 전 화면 저장
        window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', this.lv_RouteParam)

        if (this.lv_RouteParam.aprvType === '01') {
          this.aprvType = this.lv_RouteParam.aprvType
          this.fn_MovePage()
        }

      } else { // 라우터 파라미터가 없으면 뒤로가기로 진입한 것으로 판단
        /* // 백키 이동시 보장가입설계 화면으로 무한 로딩되는 버그 수정을 위해 임시로 주석처리함 (2023.07.20)

        // 모바일 고객등록으로 바로 진입했을 경우
        if (!_.isEmpty(preSrnObj)) {
          if (preSrnObj.aprvType === '01' && !this.$bizUtil.isEmpty(preSrnObj.srnId)) {
            this.$router.push({ name: preSrnObj.srnId, params: preSrnObj.rtnParam })
          }
        }
        */
      }

      this.preSrnId = !this.$bizUtil.isEmpty(this.lv_RouteParam.srnId) ? this.lv_RouteParam.srnId : ''
      this.lv_RouteParam.custNm = !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : ''
    },
    /***********************************************
     * @function fn_MovePage
     * @name     화면전환
     * @notice   - 모바일동의: MSPCM001M 
     *           - 서면 동의 : MSPCM040M
     *           - 비대면 고객동의: MSPCM133M
    ***********************************************/
    fn_MovePage () {
      let pParam = {
        srnId: this.$options.screenId,     // 진입 화면ID
        custNm: this.lv_RouteParam.custNm  // 고객명
      }

      pParam = !_.isEmpty(this.lv_RouteParam) ? this.lv_RouteParam : {}
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      if (this.aprvType === '01') {
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)
        this.$router.push({ name: 'MSPCM001M', params: pParam })
      } else if (this.aprvType === '02') {
        // GA지류동의서
        let url = ''
        if (process.env.NODE_ENV === 'local') {
          url = 'http://localhost:8081/#/main/bc/TSSBC700M'
        } else if (process.env.NODE_ENV === 'development') {
          url = 'http://wb1.t.tss.samsunglife.kr:8850/#/main/bc/TSSBC700M' // ci 개발
        } else if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
          url = 'https://vmws.samsunglife.com:8440/#/main/bc/TSSBC700M' // ci 검증
        } else {
          url = 'https://mws.samsunglife.com:8440/#/main/bc/TSSBC700M' // 운영
        }
        
        if (this.$commonUtil.isMobile()) { // 모바일에서 작동
          console.log('모바일작동!!!')
          window.fdpbridge.exec('intentChromeBrowserPlugin', {uri: url}, () => {}, () => {})
        } else { // PC에서 작동
          console.log('PC작동????')
          window.open(url, '_blank')
        }
      } else if (this.aprvType === '03') {
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)
        this.$router.push({ name: 'MSPCM133M', params: pParam })
      }
    },
    /***********************************************
     * @function fn_BtnClick
     * @name     현황조회
     * @notice   비대면동의 진행현황 화면 전환
    ***********************************************/
    fn_BtnClick () {
      this.$router.push({name: 'MSPCM135M'})
    },
    fn_BtnClickJr () {
      this.$router.push({name: 'MSPCM502M'})
    },
    /******************************************************************************
     * @function fn_HistoryBack
     * @notice   뒤로가기 버튼
    ******************************************************************************/
    fn_HistoryBack () {
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)
      
      if (this.$route.query?._isMenuItem) {
        this.$router.push({ name: 'MSPBC002M' })
        return
      }
      this.$router.go(-1)
    },
    /*********************************************************
     * @function fn_SrchCallback
     * @name     콜백함수
     * @notice   컨설턴트 콜백함수
    *********************************************************/
    fn_SrchCallback(pData) {
      console.log('콜백 파라미터>>>>', pData)
      this.vEplyNo = pData.eplyNo // 컨설턴트ID
      this.vGssEplyNm = pData.gssEplyNm // 컨설턴트명

      console.log("변경한 컨설턴트 gssEplyInfo :::::::::::" + JSON.stringify(this.getStore('cmStore').getters.getState.gssEplyInfo))
    },
    fn_openMSPCM217M (){ //동의만료예정고객
      this.$bcUtil.setRntConctMenuInfoSrnId(this, {srnId: 'MSPCM217M'})
      this.$router.push({name:'MSPCM217M'})
    },
    fn_openMSPCM170M(){  //정보활용동의현황
      this.$bcUtil.setRntConctMenuInfoSrnId(this, {srnId: 'MSPCM170M'})
      this.$router.push({name:'MSPCM170M'})
    },
    fn_openMSPCM220M(){  //미승인고객
      this.$bcUtil.setRntConctMenuInfoSrnId(this, {srnId: 'MSPCM220M'})
      this.$router.push({name:'MSPCM220M'})
    },
    fn_showGuideToolTip(){
      localStorage.setItem("READ_GA_AGREE_BUBBLETIP", true)
      this.showGuideToolTip = false
    }
  } // ::Methods End
}
</script>